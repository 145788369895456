jQuery.noConflict();
const ajaxUrl = '/ajax.php';

jQuery(function ($) {        
    
    $('.mask-code').mask('0-0-0-0');
    $('.mask-date').mask('00/00/0000');
    $('.mask-time').mask('00:00:00');
    $('.mask-date_time').mask('00/00/0000 00:00:00');
    $('.mask-cep').mask('00000-000');
    $('.mask-cpf').mask('000.000.000-00');
    $('.mask-cnpj').mask('00.000.000/0000-00');

    var TelMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    }, telOptions = {
        onKeyPress: function (val, e, field, options) {
            field.mask(TelMaskBehavior.apply({}, arguments), options);
        }
    };

    $('.mask-tel').mask(TelMaskBehavior, telOptions);   

    lightbox.option({
        'albumLabel': "Imagem %1 de %2"
    });

    $('form').on('keydown', function(e) {
        if (e.which == 13) {
            return false;
        }
    });

});